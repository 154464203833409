<template>
    <div>
        <div v-if="authUserPermission['item-name-view']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>      
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card mb-4">
                            <div class="card-header mt--4">
                                <div class="row">
                                    <div class="col">
                                        <h3 class="mb-0">{{ tt('standard_item_name') }}</h3>
                                    </div>
                                    <div class="col text-right">
                                        <base-button size="sm" type="transparant" @click="search">
                                            <i class="fa fa-search"></i>
                                        </base-button>
                                        <base-button size="sm" type="transparant" @click="create" v-if="authUserPermission['item-name-create']">
                                            <i class="fa fa-plus-square"></i>
                                        </base-button>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <el-table height="300px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.data" v-if="!onLoad.standard_item_name" ref="singleTable"
                                    highlight-current-row
                                    @current-change="handleCurrentChange"
                                    style="width: 100%">
                                    <el-table-column :label="tt('inc')" :prop="tt('inc')" min-width="100px" sortable>
                                        <template v-slot="{row}">
                                            {{row.inc}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="tt('item_name')" :prop="tt('item_name')" min-width="437px" sortable>
                                        <template v-slot="{row}">
                                            {{row.item_name}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="tt('short_name')" :prop="tt('short_name')" min-width="237px" sortable>
                                        <template v-slot="{row}">
                                            {{row.short_name}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="action" width="100">
                                        <template v-slot="{row}">
                                            <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true" v-if="authUserPermission['item-name-update'] || authUserPermission['item-name-delete']">
                                                <span class="btn btn-sm btn-icon-only text-light">
                                                    <i class="fas fa-ellipsis-v mt-2"></i>
                                                </span>
                                                <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                                    <el-dropdown-item :command="{action:'edit', data:row.id}" v-if="authUserPermission['item-name-update']">{{ tt('edit') }}</el-dropdown-item>
                                                    <el-dropdown-item :command="{action:'remove', data:row.id}" v-if="authUserPermission['item-name-delete']">{{ tt('delete') }}</el-dropdown-item>
                                                </el-dropdown-menu>
                                            </el-dropdown>
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <page-loading v-else/>
                            </div>
                            <div class="card-footer pb-0 ">   
                                <span class="float-left">
                                    <base-pagination :page-count="table.page.last_page" v-model="table.page.current_page" @click.native="changePage(table.page.current_page)" size="sm"></base-pagination>
                                </span>                             
                                <span class="float-right">
                                    {{ tt('page_info', {size: (table.page.current_page - 1) * table.page.per_page + table.data.length, total: table.total}) }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-3">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card mt--4">
                                    <div class="card-body">
                                        <nav class="mb-4">
                                            <div class="nav nav-tabs" id="nav-tab" role="tablist" style="font-size: 12px;overflow-x: auto;overflow-y: hidden;flex-wrap: nowrap;">
                                                <a :class="tabs.definition ? 'nav-item nav-link active' : 'nav-item nav-link'" data-toggle="tab" @click="tab('definition')" role="tab" aria-controls="nav-home" :aria-selected="tabs.definition"><b>DEFINITION</b></a>
                                                <a :class="tabs.colloquial ? 'nav-item nav-link active' : 'nav-item nav-link'" data-toggle="tab" @click="tab('colloquial')" role="tab" aria-controls="nav-contact" :aria-selected="tabs.colloquial"><b>COLLOQUIAL</b></a>
                                                <a :class="tabs.ngc ? 'nav-item nav-link active' : 'nav-item nav-link'" data-toggle="tab" @click="tab('ngc')" role="tab" aria-controls="nav-contact" :aria-selected="tabs.ngc"><b>NATO</b></a>
                                                <a :class="tabs.mesc ? 'nav-item nav-link active' : 'nav-item nav-link'" data-toggle="tab" @click="tab('mesc')" role="tab" aria-controls="nav-contact" :aria-selected="tabs.mesc"><b>MESC</b></a>
                                                <a :class="tabs.unspsc ? 'nav-item nav-link active' : 'nav-item nav-link'" data-toggle="tab" @click="tab('unspsc')" role="tab" aria-controls="nav-contact" :aria-selected="tabs.unspsc"><b>UNSPSC</b></a>
                                                <a :class="tabs.mat_type ? 'nav-item nav-link active' : 'nav-item nav-link'" data-toggle="tab" @click="tab('mat_type')" role="tab" aria-controls="nav-contact" :aria-selected="tabs.mat_type"><b>MATERIAL TYPE</b></a>
                                                <a :class="tabs.mat_group ? 'nav-item nav-link active' : 'nav-item nav-link'" data-toggle="tab" @click="tab('mat_group')" role="tab" aria-controls="nav-contact" :aria-selected="tabs.mat_group"><b>MATERIAL GROUP</b></a>
                                                <!-- <a :class="tabs.service_category ? 'nav-item nav-link active' : 'nav-item nav-link'" data-toggle="tab" @click="tab('service_category')" role="tab" aria-controls="nav-contact" :aria-selected="tabs.service_category"><b>SERVICE CATEGORY</b></a>
                                                <a :class="tabs.service_group ? 'nav-item nav-link active' : 'nav-item nav-link'" data-toggle="tab" @click="tab('service_group')" role="tab" aria-controls="nav-contact" :aria-selected="tabs.service_group"><b>SERVICE GROUP</b></a> -->
                                                <a :class="tabs.image_item_name ? 'nav-item nav-link active' : 'nav-item nav-link'" data-toggle="tab" @click="tab('image_item_name')" role="tab" aria-controls="nav-contact" :aria-selected="tabs.image_item_name"><b>IMAGE</b></a>
                                            </div>
                                        </nav>

                                        <!-- TAB DEFINITION -->
                                        <div v-if="tabs.definition" style="height: 300px">
                                            <tabs tabNavClasses="flex-sm-row" v-if="!onLoad.definition">
                                                <tab-pane title="English" class="input-xs">
                                                    <div class="mt-3">
                                                        <span>{{selectedData.definition.definition_eng}}</span>
                                                    </div>
                                                </tab-pane>
                                                <tab-pane title="Indonesia">
                                                    <div class="mt-3">
                                                        <span>{{selectedData.definition.definition_ind}}</span>
                                                    </div>
                                                </tab-pane>
                                            </tabs>
                                            <page-loading v-else/>
                                        </div> 

                                        <!-- TAB COLLOQUIAL -->
                                        <div v-else-if="tabs.colloquial" class="mt--4">
                                            <el-table height="300px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="selectedData.colloquial" v-if="!onLoad.colloquial">
                                                <el-table-column :label="tt('colloquial')" :prop="tt('colloquial')" sortable>
                                                    <template v-slot="{row}">
                                                        {{row.colloquial_name}}
                                                    </template>
                                                </el-table-column>
                                                <el-table-column width="100">
                                                    <template v-slot="{row}">
                                                        <i v-if="authUserPermission['item-name-delete-colloquial']" class="fa fa-trash text-danger text-right" @click="deleteColloquial(row.id)"></i>
                                                    </template>
                                                </el-table-column>
                                            </el-table>
                                            <page-loading v-else/>
                                            <template v-if="authUserPermission['item-name-create-colloquial']">
                                                <base-button size="sm" type="default" class="mt-3" @click="addColloquial" v-if="tabs.colloquial && currentRow" block>{{ tt('add_new') }}</base-button>
                                            </template>
                                        </div> 

                                        <!-- TAB NGC -->
                                        <div v-else-if="tabs.ngc" class="mt--4">
                                            <div class="row">
                                                <div class="col-6">
                                                    <el-table height="300px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="selectedData.ngc" v-if="!onLoad.ngc" ref="singleTable"
                                                        highlight-current-row
                                                        @current-change="handleCurrentChangeNgc"
                                                        style="width: 100%">
                                                        <el-table-column :label="tt('group_class')" :prop="tt('group_class')" min-width="100px" sortable>
                                                            <template v-slot="{row}">
                                                                {{row.nsc}}
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column :label="tt('description')" :prop="tt('description')" min-width="100px" sortable>
                                                            <template v-slot="{row}">
                                                                {{row.description}}
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column width="100">
                                                            <template v-slot="{row}">
                                                                <i v-if="authUserPermission['item-name-delete-group-class']" class="fa fa-trash text-danger text-right" @click="deleteNgc(row.id)"></i>
                                                            </template>
                                                        </el-table-column>
                                                    </el-table>
                                                    <page-loading v-else/>
                                                    <template v-if="authUserPermission['item-name-create-group-class']">
                                                        <base-button size="sm" type="default" class="mt-3" @click="addNgc" v-if="tabs.ngc && currentRow" block>{{ tt('add_new') }}</base-button>
                                                    </template>
                                                </div>
                                                <div class="col-6">
                                                    <div class="card m-0 shadow-sm">
                                                        <div class="card-header">
                                                            <h3 class="mb-0">{{ tt('group_definition') }}</h3>
                                                        </div>
                                                        <div class="card-body" style="height: 280px">
                                                            <div v-if="!onLoad.group_definition">
                                                                {{selectedData.group_definition}}
                                                            </div>
                                                            <page-loading v-else/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- TAB MESC -->
                                        <div v-else-if="tabs.mesc" class="mt--4">
                                            <el-table height="300px" class="table-responsive table-flush" row-key="id" header-row-class-name="thead-light" :data="selectedData.mesc" v-if="!onLoad.mesc">
                                                <el-table-column :label="tt('code')" :prop="tt('code')" min-width="100px" sortable>
                                                    <template v-slot="{row}">
                                                        {{row.code}}
                                                    </template>
                                                </el-table-column>
                                                <el-table-column :label="tt('description')" :prop="tt('description')" min-width="100px" sortable>
                                                    <template v-slot="{row}">
                                                        {{row.description}}
                                                    </template>
                                                </el-table-column>
                                                <el-table-column width="100">
                                                    <template v-slot="{row}">
                                                        <div v-if="row.type != 'Sub Sub Group'">
                                                            <i class="fa fa-map-marker text-default text-right mr-1" style="cursor:pointer" v-if="row.type != 'Main Group'" @click="maps(row.type, row.key)"></i>
                                                            <i class="fa fa-plus text-default text-right mr-1" style="cursor:pointer" @click="createMesc(row.children, row.key, row.type)"></i>
                                                            <i class="fa fa-trash text-danger text-right ml-1" style="cursor:pointer" @click="deleteMesc(row.children, row.key)"></i>
                                                        </div>
                                                        <div v-else>
                                                            <i class="fa fa-map-marker text-default text-right mr-1" style="cursor:pointer" @click="maps(row.type, row.key)"></i>
                                                            <i class="fa fa-trash text-danger text-right" style="cursor:pointer" @click="deleteMesc(row.children, row.key)"></i>
                                                        </div>
                                                    </template>
                                                </el-table-column>
                                            </el-table>
                                            <page-loading v-else/>
                                            <template>
                                                <base-button size="sm" class="mt-3" type="default" @click="addMesc" v-if="tabs.mesc && currentRow" block>{{ tt('add_new') }}</base-button>
                                            </template>
                                        </div>

                                        <!-- TAB MAT TYPE -->
                                        <div v-else-if="tabs.mat_type" class="mt--4">
                                            <el-table height="300px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="selectedData.mat_type" v-if="!onLoad.mat_type">
                                                <el-table-column :label="tt('material_type')" :prop="tt('material_type')" min-width="100px" sortable>
                                                    <template v-slot="{row}">
                                                        {{row.code}}
                                                    </template>
                                                </el-table-column>
                                                <el-table-column :label="tt('description')" :prop="tt('description')" min-width="100px" sortable>
                                                    <template v-slot="{row}">
                                                        {{row.description}}
                                                    </template>
                                                </el-table-column>
                                                <el-table-column :label="tt('definition')" :prop="tt('definition')" min-width="100px" sortable>
                                                    <template v-slot="{row}">
                                                        {{row.definition}}
                                                    </template>
                                                </el-table-column>
                                                <el-table-column width="100">
                                                    <template v-slot="{row}">
                                                        <i v-if="authUserPermission['item-name-delete-material-type']" class="fa fa-trash text-danger text-right" @click="deleteMatType(row.id)"></i>
                                                    </template>
                                                </el-table-column>
                                            </el-table>
                                            <page-loading v-else/>
                                            <template v-if="authUserPermission['item-name-create-material-type']">
                                                <base-button size="sm" class="mt-3" type="default" @click="addMatType" v-if="tabs.mat_type && currentRow" block>{{ tt('add_new') }}</base-button>
                                            </template>
                                        </div>

                                        <!-- TAB MAT GROUP -->
                                        <div v-else-if="tabs.mat_group" class="mt--4">
                                            <el-table height="300px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="selectedData.mat_group" v-if="!onLoad.mat_group">
                                                <el-table-column :label="tt('material_group')" :prop="tt('material_group')" min-width="100px" sortable>
                                                    <template v-slot="{row}">
                                                        {{row.code}}
                                                    </template>
                                                </el-table-column>
                                                <el-table-column :label="tt('description')" :prop="tt('description')" min-width="100px" sortable>
                                                    <template v-slot="{row}">
                                                        {{row.description}}
                                                    </template>
                                                </el-table-column>
                                                <el-table-column :label="tt('definition')" :prop="tt('definition')" min-width="100px" sortable>
                                                    <template v-slot="{row}">
                                                        {{row.definition}}
                                                    </template>
                                                </el-table-column>
                                                <el-table-column width="100">
                                                    <template v-slot="{row}">
                                                        <i v-if="authUserPermission['item-name-delete-material-group']" class="fa fa-trash text-danger text-right" @click="deleteMatGroup(row.id)"></i>
                                                    </template>
                                                </el-table-column>
                                            </el-table>
                                            <page-loading v-else/>
                                            <template v-if="authUserPermission['item-name-create-material-group']">
                                                <base-button size="sm" class="mt-3" block type="default" @click="addMatGroup" v-if="tabs.mat_group && currentRow">{{ tt('add_new') }}</base-button>
                                            </template>
                                        </div>

                                        <!-- TAB SERVICE CATEGORY -->
                                        <div v-else-if="tabs.service_category" class="mt--4">
                                            <el-table height="300px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="selectedData.service_category" v-if="!onLoad.service_category">
                                                <el-table-column :label="tt('service_category')" :prop="tt('service_category')" min-width="100px" sortable>
                                                    <template v-slot="{row}">
                                                        {{row.code}}
                                                    </template>
                                                </el-table-column>
                                                <el-table-column :label="tt('description')" :prop="tt('description')" min-width="100px" sortable>
                                                    <template v-slot="{row}">
                                                        {{row.description}}
                                                    </template>
                                                </el-table-column>
                                                <el-table-column width="100">
                                                    <template v-slot="{row}">
                                                        <i v-if="authUserPermission['item-name-delete-service-category']" class="fa fa-trash text-danger text-right" @click="deleteServiceCategory(row.id)"></i>
                                                    </template>
                                                </el-table-column>
                                            </el-table>
                                            <page-loading v-else/>
                                            <template v-if="authUserPermission['item-name-create-service-category']">
                                                <base-button size="sm" class="mt-3" block type="default" @click="addServiceCategory" v-if="tabs.service_category && currentRow">{{ tt('add_new') }}</base-button>
                                            </template>
                                        </div>

                                        <!-- TAB SERVICE GROUP -->
                                        <div v-else-if="tabs.service_group" class="mt--4">
                                            <el-table height="300px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="selectedData.service_group" v-if="!onLoad.service_group">
                                                <el-table-column :label="tt('service_group')" :prop="tt('service_group')" min-width="100px" sortable>
                                                    <template v-slot="{row}">
                                                        {{row.code}}
                                                    </template>
                                                </el-table-column>
                                                <el-table-column :label="tt('description')" :prop="tt('description')" min-width="100px" sortable>
                                                    <template v-slot="{row}">
                                                        {{row.description}}
                                                    </template>
                                                </el-table-column>
                                                <el-table-column width="100">
                                                    <template v-slot="{row}">
                                                        <i v-if="authUserPermission['item-name-delete-service-group']" class="fa fa-trash text-danger text-right" @click="deleteServiceGroup(row.id)"></i>
                                                    </template>
                                                </el-table-column>
                                            </el-table>
                                            <page-loading v-else/>
                                            <template v-if="authUserPermission['item-name-create-service-group']">
                                                <base-button size="sm" class="mt-3" block type="default" @click="addServiceGroup" v-if="tabs.service_group && currentRow">{{ tt('add_new') }}</base-button>
                                            </template>
                                        </div>

                                        <!-- TAB IMAGE -->
                                        <div v-else-if="tabs.image_item_name">
                                            <div class="container-fluid">
                                                <div class="row">
                                                    <div v-for="v in selectedData.image_item_name">
                                                        <el-image 
                                                            :src="v.image" 
                                                            :preview-src-list="selectedData.all_image_item_name"
                                                            style="width: 100px; height: 100px">
                                                        </el-image>
                                                        <i v-if="authUserPermission['item-name-delete-image']" class="fa fa-trash text-danger text-right" @click="deleteImageItemName(v.id)"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <template v-if="authUserPermission['item-name-create-image']">
                                                <base-button size="sm" class="mt-3" block type="default" @click="addImageItemName" v-if="tabs.image_item_name && currentRow">{{ tt('add_new') }}</base-button>
                                            </template>
                                        </div>

                                        <!-- TAB UNSPSC -->
                                        <div v-else-if="tabs.unspsc" class="mt--4">
                                            <el-table height="300px" class="table-responsive table-flush" row-key="id" header-row-class-name="thead-light" :data="selectedData.unspsc" v-if="!onLoad.unspsc">
                                                <el-table-column :label="tt('code')" :prop="tt('code')" min-width="100px" sortable>
                                                    <template v-slot="{row}">
                                                        {{row.code}}
                                                    </template>
                                                </el-table-column>
                                                <el-table-column :label="tt('description')" :prop="tt('description')" min-width="100px" sortable>
                                                    <template v-slot="{row}">
                                                        {{row.title}}
                                                    </template>
                                                </el-table-column>
                                                <el-table-column width="100">
                                                    <template v-slot="{row}">
                                                        <i class="fa fa-plus text-default text-right mr-1" style="cursor:pointer" @click="formUnspsc(row)" v-if="row.level != 4"></i>
                                                    </template>
                                                </el-table-column>
                                            </el-table>
                                            <page-loading v-else/>
                                            <template>
                                                <base-button size="sm" class="mt-3" type="default" @click="formUnspsc" block>{{ tt('add_new') }}</base-button>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <validation-observer ref="form" v-slot="{invalid}">
                <modal :show.sync="form.show">
                    <template slot="header">
                        <h5 class="modal-title">{{form.title}}</h5>
                    </template>
                    <div>
                        <base-alert type="danger" v-if="errorMessage">
                            <p><strong>{{ errorMessage.message }}</strong></p>
                            <div v-if="errorMessage.data.length != 0">
                                <span v-for="message in errorMessage.data">
                                    {{ message[0] }}<br>
                                </span>
                            </div>
                        </base-alert>

                        <label class="form-control-label">{{ tt('inc') }} <span class="text-danger">*</span></label>
                        <div v-if="form.add" class="row">
                            <div class="col-md-7">
                                <base-input :name="tt('inc')" :placeholder="tt('inc')" v-model="standard_item_name.inc" rules="required|max:5|min:5"></base-input>
                            </div>
                            <div class="col-md-5">
                                <base-button type="default" @click="generateInc" :disabled="btnGenerate.onLoading">
                                    <span v-if="btnGenerate.onLoading">
                                        <i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}
                                    </span>
                                    <span v-else>
                                        {{ tt('generate') }}
                                    </span>
                                </base-button>
                            </div>
                        </div>
                        <div v-else>
                            <base-input :name="tt('inc')" :placeholder="tt('inc')" v-model="standard_item_name.inc" disabled></base-input>
                        </div>
                        <label class="form-control-label">{{ tt('item_name') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('item_name')" :placeholder="tt('item_name')" v-model="standard_item_name.item_name" rules="required"></base-input>
                        <label class="form-control-label">{{ tt('short_name') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('short_name')" :placeholder="tt('short_name')" v-model="standard_item_name.short_name" rules="required"></base-input>
                        <label class="form-control-label">{{ tt('english_definition') }}</label>
                        <base-input :name="tt('english_definition')" :placeholder="tt('english_definition')" v-model="standard_item_name.definition_eng"></base-input>
                        <label class="form-control-label">{{ tt('indonesia_definition') }}</label>
                        <base-input :name="tt('indonesia_definition')" :placeholder="tt('indonesia_definition')" v-model="standard_item_name.definition_ind"></base-input>
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="form.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="save" :disabled="btnSave.onLoading || invalid">
                            <span v-if="btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                <span v-if="form.add">{{ tt('add') }}</span>
                                <span v-else>{{ tt('edit') }}</span>
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>

            <modal :show.sync="formSearch.show">
                <template slot="header">
                    <h5 class="modal-title">{{ tt('search_item_name') }}</h5>
                </template>
                <div>
                    <base-input :name="tt('item_name')" :placeholder="tt('inc_or_item_name')" v-model="searchStandardItemName.item_name" v-on:keyup.enter="filter"></base-input>
                    <base-input :name="tt('colloquial')" :placeholder="tt('colloquial')" v-model="searchStandardItemName.colloquial" v-on:keyup.enter="filter"></base-input>
                    <base-input :name="tt('definition')" :placeholder="tt('definition')" v-model="searchStandardItemName.definition" v-on:keyup.enter="filter"></base-input>
                    <base-input :name="tt('group_class')" :placeholder="tt('group_class')" v-model="searchStandardItemName.group_class" v-on:keyup.enter="filter"></base-input>
                </div>
                <template slot="footer">
                    <base-button type="secondary" @click="formSearch.show = false">{{ tt('close') }}</base-button>
                    <base-button type="primary" v-on:click="filter" :disabled="btnSearch.onLoading">
                        <span v-if="btnSearch.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                        <span v-else>
                            {{tt('search')}}
                        </span>
                    </base-button>
                </template>
            </modal>

            <validation-observer ref="formColloquial" v-slot="{invalid}">
                <modal :show.sync="formColloquial.show">
                    <template slot="header">
                        <h5 class="modal-title">{{ tt('add_new_colloquial') }}</h5>
                    </template>
                    <div>
                        <base-input :name="tt('colloquial')" :placeholder="tt('colloquial')" v-model="colloquial.colloquial_name" rules="required"></base-input>
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="formColloquial.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="saveColloquial" :disabled="btnAddColloquial.onLoading || invalid">
                            <span v-if="btnAddColloquial.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                {{tt('add')}}
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>

            <validation-observer v-slot="{invalid}">
                <modal :show.sync="formNgc.show">
                    <template slot="header">
                        <h5 class="modal-title">{{ tt('add_new_group_class') }}</h5>
                    </template>
                    <div class="row">
                        <el-autocomplete
                            class="inline-input col-md-12"
                            v-model="ngc.nsc"
                            :fetch-suggestions="querySearchNgc"
                            :placeholder="tt('choose_group_class')"
                            @select="handleSelect"
                            size="large"
                        ></el-autocomplete>
                        <!-- <base-input :name="tt('group_class')" :placeholder="tt('group_class')" v-model="ngc.nsc" rules="required"></base-input> -->
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="formNgc.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="saveNgc" :disabled="btnAddNgc.onLoading || invalid">
                            <span v-if="btnAddNgc.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                {{tt('add')}}
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>

            <validation-observer v-slot="{invalid}">
                <modal :show.sync="formMatType.show">
                    <template slot="header">
                        <h5 class="modal-title">{{ tt('add_new_material_type') }}</h5>
                    </template>
                    <div class="form-group">                        
                        <el-select filterable class="select-danger" v-model="mat_type.material_type_code" :placeholder="tt('choose_material_type')">
                            <el-option class="select-danger" :value="mt['code']" :label="mt['code'] + ' - ' + mt['value']" :key="mt['code'] + ' - ' + mt['value']" v-for="mt in materialType">{{ mt['code'] }} - {{ mt['value'] }}</el-option>
                        </el-select>                        
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="formMatType.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="saveMatType" :disabled="btnAddMatType.onLoading || invalid">
                            <span v-if="btnAddMatType.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                {{tt('add')}}
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>

            <validation-observer v-slot="{invalid}">
                <modal :show.sync="formMatGroup.show">
                    <template slot="header">
                        <h5 class="modal-title">{{ tt('add_new_material_group') }}</h5>
                    </template>
                    <div class="row">
                        <el-autocomplete
                            class="inline-input col-md-12"
                            v-model="mat_group.material_group_code"
                            :fetch-suggestions="querySearchMatGroup"
                            :placeholder="tt('choose_material_group')"
                            @select="handleSelect"
                            size="large"
                        ></el-autocomplete>
                        <!-- <base-input :name="tt('material_group')" :placeholder="tt('material_group')" v-model="mat_group.material_group_code" rules="required"></base-input> -->
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="formMatGroup.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="saveMatGroup" :disabled="btnAddMatGroup.onLoading || invalid">
                            <span v-if="btnAddMatGroup.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                {{tt('add')}}
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>

            <validation-observer v-slot="{invalid}">
                <modal :show.sync="formServiceCategory.show">
                    <template slot="header">
                        <h5 class="modal-title">{{ tt('add_new_service_category') }}</h5>
                    </template>
                    <div class="row">
                        <el-autocomplete
                            class="inline-input col-md-12"
                            v-model="service_category.service_category_code"
                            :fetch-suggestions="querySearchServiceCategory"
                            :placeholder="tt('choose_service_category')"
                            @select="handleSelect"
                            size="large"
                        ></el-autocomplete>
                        <!-- <base-input :name="tt('service_category')" :placeholder="tt('service_category')" v-model="service_category.service_category_code" rules="required"></base-input> -->
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="formServiceCategory.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="saveServiceCategory" :disabled="btnAddServiceCategory.onLoading || invalid">
                            <span v-if="btnAddServiceCategory.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                {{tt('add')}}
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>

            <validation-observer v-slot="{invalid}">
                <modal :show.sync="formServiceGroup.show">
                    <template slot="header">
                        <h5 class="modal-title">{{ tt('add_new_service_group') }}</h5>
                    </template>
                    <div class="row">
                        <el-autocomplete
                            class="inline-input col-md-12"
                            v-model="service_group.service_group_code"
                            :fetch-suggestions="querySearchServiceGroup"
                            :placeholder="tt('choose_service_group')"
                            @select="handleSelect"
                            size="large"
                        ></el-autocomplete>
                        <!-- <base-input :name="tt('service_group')" :placeholder="tt('service_group')" v-model="service_group.service_group_code" rules="required"></base-input> -->
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="formServiceGroup.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="saveServiceGroup" :disabled="btnAddServiceGroup.onLoading || invalid">
                            <span v-if="btnAddServiceGroup.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                {{tt('add')}}
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>

            <validation-observer v-slot="{invalid}">
                <modal :show.sync="formImageItemName.show">
                    <template slot="header">
                        <h5 class="modal-title">{{ tt('add_new_image_item_name') }}</h5>
                    </template>
                    <div>
                        <!-- <base-input :name="tt('image')" :placeholder="tt('image')" v-model="image_item_name.image" rules="required"></base-input> -->
                        <file-input @change="filesChange" ref="file"></file-input>
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="formImageItemName.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="saveImageItemName" :disabled="btnAddImageItemName.onLoading || invalid">
                            <span v-if="btnAddImageItemName.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                {{tt('add')}}
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>

            <!-- MESC -->
            <validation-observer v-slot="{invalid}">
                <modal :show.sync="formMesc.show">
                    <template slot="header">
                        <h5 class="modal-title">
                            {{ tt('add_new_mesc')}}
                        </h5>
                    </template>
                    <div>
                        <base-input :name="tt('main_group')" rules="required">
                            <el-select  v-model="mescMain" :placeholder="tt('main_group')" :remote-method="querySearchMescMainG" :loading="selectSearch.mainG" filterable remote reserve-keyword> 
                                <el-option v-for="mg in mescMainGroup" :key="mg.key" :value="mg.key"  :label="mg.code +' - '+ mg.description"></el-option>
                            </el-select>
                        </base-input>
                        <base-input :name="tt('sub_group')">
                            <el-select  v-model="mescSubMultiple" :placeholder="tt('sub_group')" clearable multiple> 
                                <el-option v-for="sb in mescSubGroup" :key="sb.key" :value="sb.key"  :label="sb.code +' - '+ sb.description"></el-option>
                            </el-select>
                        </base-input>
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="formMesc.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="saveMesc()" :disabled="btnAddMesc.onLoading || invalid">
                            <span v-if="btnAddMesc.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                {{tt('add')}}
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>
            
            <!-- ADD CHILD MESC -->
            <validation-observer v-slot="{invalid}">
                <modal :show.sync="formMescByParent">
                    <template slot="header">
                        <h5 class="modal-title">
                            {{ tt('add_new_mesc')}}
                        </h5>
                    </template>
                    <div>
                        <base-input :name="tt('sub_group')" v-if="fromMainG">
                            <el-select  v-model="mescSubMultiple" :placeholder="tt('sub_group')" clearable multiple> 
                                <el-option v-for="sb in mescSubGroup" :key="sb.key" :value="sb.key"  :label="sb.code +' - '+ sb.description"></el-option>
                            </el-select>
                        </base-input>
                        <base-input :name="tt('sub_sub_group')" v-else>
                            <el-select  v-model="mescSubSubMultiple" :placeholder="tt('sub_sub_group')" clearable multiple> 
                                <el-option v-for="ssb in mescSubSubGroup" :key="ssb.key" :value="ssb.key"  :label="ssb.code +' - '+ ssb.description"></el-option>
                            </el-select>
                        </base-input>
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="formMescByParent = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="saveMescParent()" :disabled="btnAddMesc.onLoading || invalid" v-if="fromMainG">
                            <span v-if="btnAddMesc.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                {{tt('add')}}
                            </span>
                        </base-button>
                        <base-button type="primary" v-on:click="saveMescChild()" :disabled="btnAddMesc.onLoading || invalid" v-else>
                            <span v-if="btnAddMesc.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                {{tt('add')}}
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>

            <!-- FORM CHARACTERISTIC MESC -->
            <validation-observer v-slot="{invalid}">
                <modal :show.sync="mapping.show" size="lg">
                    <template slot="header">
                        <h5 class="modal-title">
                            {{ tt('add_characteristic')}}
                        </h5>
                    </template>
                    <div>
                        <mesc-characteristic :inc="incMesc" :key_mesc="mapping.keyMesc" @close="close()"></mesc-characteristic>
                    </div>
                    <!-- <template slot="footer">
                        <base-button type="secondary" @click="formMesc.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="saveMescChild()" :disabled="btnAddMesc.onLoading || invalid">
                            <span v-if="btnAddMesc.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                {{tt('add')}}
                            </span>
                        </base-button>
                    </template> -->
                </modal>
            </validation-observer>

             <!-- UNSPSC -->
            <validation-observer v-slot="{invalid}">
                <modal :show.sync="dataUnspsc.show">
                    <template slot="header">
                        <h5 class="modal-title">
                            {{ tt('edit_unspsc')}}
                        </h5>
                    </template>
                    <div>
                        <base-input :name="tt('segment')" rules="required" style="width:auto" v-if="dataUnspsc.activeForm == 'segment'">
                            <el-select  v-model="dataUnspsc.sg_code" :placeholder="tt('segment')" filterable remote reserve-keyword :remote-method="searchSegmentCode" multiple> 
                                <el-option v-for="sg in segmentCodes" :value="sg.identity_relation_code" :key="sg.id" :label="sg.code + ' ' + sg.title"></el-option>
                            </el-select>
                        </base-input>
                         <base-input :name="tt('family')" rules="required" v-if="dataUnspsc.activeForm == 'family'">
                            <el-select  v-model="dataUnspsc.fm_code" :placeholder="tt('family')" filterable remote reserve-keyword multiple> 
                                    <el-option v-for="fm in familyCodes" :key="fm.id"  :value="fm.identity_relation_code" :label=" fm.code + ' ' + fm.title"></el-option>
                            </el-select>
                        </base-input>
                        <base-input :name="tt('classification')" v-if="dataUnspsc.activeForm == 'classification'">
                            <el-select  v-model="dataUnspsc.cl_code" :placeholder="tt('classification')" filterable remote reserve-keyword multiple> 
                                <el-option v-for="cl in classificationCodes" :key="cl.id" :value="cl.identity_relation_code" :label="cl.code + ' ' + cl.title"></el-option>
                            </el-select>
                        </base-input>
                         <base-input :name="tt('commodity')" rules="required" v-if="dataUnspsc.activeForm == 'commodity'">
                            <el-select  v-model="dataUnspsc.cm_code" :placeholder="tt('commodity')" filterable remote reserve-keyword multiple> 
                                <el-option v-for="cm in commodityCodes" :key="cm.id" :value="cm.identity_relation_code" :label="cm.code + ' ' + cm.title"></el-option>
                            </el-select>
                        </base-input>
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="formMesc.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="saveUnspsc()" :disabled="dataUnspsc.btnSave || invalid">
                            <span v-if="dataUnspsc.btnSave"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                {{tt('add')}}
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>
        </div>
        <noaccess v-else/>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import standardItemName from '@/services/dictionary/standardItemName.service';
    import natoGroupClass from '@/services/dictionary/natoGroupClass.service';
    import materialType from '@/services/master/materialType.service';
    import materialGroup from '@/services/master/materialGroup.service';
    import serviceCategory from '@/services/master/serviceCategory.service';
    import serviceGroup from '@/services/master/serviceGroup.service';
    import router from '@/routes/router';
    import config from '@/configs/config';
    import Mesc from '@/services/dictionary/mesc.service';
    import unspsc from '@/services/dictionary/unspsc.service';

    export default {
        data() {
            return {       
                selectSearch:{
                    mainG:false,
                    loadTimeout:null,
                },
                onLoad: {
                    standard_item_name: true,
                    definition: false,
                    colloquial: false,
                    ngc: false,
                    mat_type: false,
                    mat_group: false,
                    service_category: false,
                    service_group: false,
                    image_item_name: false,
                    group_definition: false,
                    mesc:false,
                    unspsc:false,
                },
                loadTimeout: null,     
                btnSearch: {
                    onLoading: false
                },        
                btnSave: {
                    onLoading: false
                },
                btnAddColloquial: {
                    onLoading: false
                },
                btnAddNgc: {
                    onLoading: false
                },
                btnAddMesc: {
                    onLoading: false
                },
                btnAddMatType: {
                    onLoading: false
                },
                btnAddMatGroup: {
                    onLoading: false
                },
                btnAddServiceCategory: {
                    onLoading: false
                },
                btnAddServiceGroup: {
                    onLoading: false
                },
                btnAddImageItemName: {
                    onLoading: false
                },
                btnGenerate: {
                    onLoading: false
                },
                form: {
                    add: true,
                    title: this.tt('add_new_mcr'),
                    show: false,
                },
                formSearch: {
                    show: false
                }, 
                formImage: {
                    show: false
                }, 
                formColloquial: {
                    show: false
                }, 
                formNgc: {
                    show: false
                },
                formMesc:{
                    show:false,
                },
                formMatType: {
                    show: false
                }, 
                formMatGroup: {
                    show: false
                }, 
                formServiceCategory: {
                    show: false
                }, 
                formServiceGroup: {
                    show: false
                }, 
                formImageItemName: {
                    show: false
                },
                table: {
                    search: '',                    
                    total: 0,
                    data: [],
                    page: [],
                },     
                errorMessage: null,          
                searchStandardItemName: {
                    'item_name': '',
                    'colloquial': '',
                    'definition': '',
                    'group_class': '',
                },
                standardItemName: {},
                standard_item_name: {
                    inc: '',
                    definition_eng: '',
                    definition_ind: '',
                },
                unspsc:{
                    sg_code:[],
                    fm_code:[],
                    cl_code:[],
                    cm_code:[]
                },
                currentRow: null,
                tabs: {
                    definition: false,
                    colloquial: false,
                    ngc: false,
                    mat_type: false,
                    mat_group: false,
                    service_category: false,
                    service_group: false,
                    image_item_name: false,
                    mesc:false,
                    unspsc:false,
                },
                selectedData: {
                    definition: {},
                    colloquial: [],
                    ngc: [],
                    mat_type: [],
                    mat_group: [],
                    service_category: [],
                    service_group: [],
                    image_item_name: [],
                    all_image_item_name: [],
                    group_definition: '',
                    mesc:[],
                    unspsc:{}
                },
                colloquial: {},
                ngc: {},
                mat_type: {},
                mat_group: {},
                service_category: {},
                service_group: {},
                image_item_name: {},
                links_ngc: [],
                links_mat_type: [],
                links_mat_group: [],
                links_service_category: [],
                links_service_group: [],
                natoGroupClass: [],
                materialType: [],
                materialGroup: [],
                serviceCategory: [],
                serviceGroup: [],
                mescMainGroup:[],
                mescSubGroup:[],
                mescSubSubGroup:[],
                mescMain:'',
                mescSub:'',
                incMesc:'',
                fromMainG:false,
                fromSubG:false,
                formMescByParent:false,
                mescSubMultiple:[],
                mescSubSubMultiple:[],
                mapping:{
                    mainGroup:false,
                    subGroup:false,
                    subSubGroup:false,
                    show:false,
                    loading:false,
                    characteristic:[],
                    charMesc:'',
                    keyMesc:'',
                    mescCharacteristic:{
                        characteristcic:[],
                        single_or_multile:[],
                        characteristic_value:[],
                        cin_code:[],
                    }
                },
                dataUnspsc:{
                    show:false,
                    btnSave:false,
                    activeForm:'segment',
                    sg_code:[],
                    fm_code:[],
                    cl_code:[],
                    cm_code:[],
                    parent_code:""

                },
                segmentCodes:[],
                familyCodes:[],
                classificationCodes:[],
                commodityCodes:[]
            }
        },
        computed: {
            ...mapState({
                authUserPermission: state => state.auth.userPermission
            })
        },
        watch: {
            '$route': function() {
                this.get();
            },
            'mescMain': function() {
                this.getSubGroup()
                this.mescSub = ''
            },
            'mescSub': function() {
                this.getSubSubGroup()
                this.mescSubSub = []
            }
        },
        mounted() {
            this.tab('definition')
            this.get()
            this.getNatoGroupClass()
            this.getMaterialType()
            this.getMaterialGroup()
            this.getServiceCategory()
            this.getServiceGroup()
            this.links_ngc = this.natoGroupClass;
            this.links_mat_type = this.materialType;
            this.links_mat_group = this.materialGroup;
            this.links_service_category = this.serviceCategory;
            this.links_service_group = this.serviceGroup;
        },
        methods: {
            addColloquial() {
                this.formColloquial.show = true;
                this.$refs.formColloquial.reset();
                this.colloquial = {};   
            },
            saveColloquial() {
                let context = this;
                context.btnAddColloquial.onLoading = true;
                context.colloquial.inc = context.currentRow.inc;
                context.onLoad.colloquial = true;
                Api(context, standardItemName.add_colloquial(this.colloquial)).onSuccess(function(response) {
                    context.$notify({
                        message: response.data.message,
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    context.formColloquial.show = false;
                    context.handleCurrentChange(context.currentRow);
                    context.colloquial = {};
                }).onError(function(error) {
                    context.$notify({
                        message: error.response.data.message,                  
                        type: 'danger',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                }).onFinish(function() {
                    context.btnAddColloquial.onLoading = false;
                    context.onLoad.colloquial = false;
                }).call()
            },
            deleteColloquial(id) {
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        context.onLoad.colloquial = true;
                        Api(context, standardItemName.delete_colloquial(id)).onSuccess(function(response) {
                            context.$notify({
                                message: response.data.message,
                                type: 'success',
                                verticalAlign: 'bottom', 
                                horizontalAlign: 'left'
                            });
                            context.handleCurrentChange(context.currentRow);
                        }).call();
                    }
                })
            },
            getNatoGroupClass() {
                let context = this;               
                Api(context, natoGroupClass.get({per_page: 'none'})).onSuccess(function(response) {    
                    response.data.data.data.data.forEach(function(item, index) {
                        context.natoGroupClass[index] = {
                            'code': item['nsc'],
                            'value': item['nsc']
                        };
                    });                    
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.natoGroupClass = [];
                    }
                })
                .call()
            },
            addNgc() {
                this.formNgc.show = true;
            },
            saveNgc() {
                let context = this;
                context.btnAddNgc.onLoading = true;
                context.ngc.inc = context.currentRow.inc;
                context.onLoad.ngc = true;
                Api(context, standardItemName.add_group_class(this.ngc)).onSuccess(function(response) {
                    context.$notify({
                        message: response.data.message,
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    context.formNgc.show = false;
                    context.handleCurrentChange(context.currentRow);
                    context.ngc = {};
                }).onError(function(error) {
                    if (error.response.status == 500) {
                        context.$notify({
                            message: 'Group Class isn\'t exist',                  
                            type: 'danger',
                            verticalAlign: 'bottom', 
                            horizontalAlign: 'left'
                        });
                    } else {
                        context.$notify({
                            message: error.response.data.message,                  
                            type: 'danger',
                            verticalAlign: 'bottom', 
                            horizontalAlign: 'left'
                        });
                    }
                }).onFinish(function() {
                    context.btnAddNgc.onLoading = false;
                    context.onLoad.ngc = false;
                }).call()
            },
            deleteNgc(id) {
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        context.onLoad.ngc = true;
                        Api(context, standardItemName.delete_group_class(id)).onSuccess(function(response) {
                            context.$notify({
                                message: response.data.message,
                                type: 'success',
                                verticalAlign: 'bottom', 
                                horizontalAlign: 'left'
                            });
                            context.handleCurrentChange(context.currentRow);
                        }).call();
                    }
                })
            },
            addMesc(){
                this.formMesc.show = true;
            },
            saveMesc(){
                let data = [];
                let context= this;
                let formData  = new FormData();
                let checkMesc = false;

                this.btnAddMesc.onLoading = true;

                // checkMesc = this.selectedData['mesc'].includes(this.mescMain);
                if(this.selectedData['mesc'].length > 0){
                    for(var i = 0; i < this.selectedData.mesc.length;i++){
                        if(this.selectedData.mesc[i].key == this.mescMain){
                            checkMesc = true;
                        }else{
                            checkMesc = false;
                        }
                    }
                }
            
                
                if(this.incMesc != '' && checkMesc == false){
                    if(this.mescSubMultiple.length > 0){
                        for(var i=0; i < this.mescSubMultiple.length;i++){
                            data.push(this.mescSubMultiple[i])
                        }
                    }
                   data.push(this.mescMain);
                    for(var i = 0; i < data.length;i++){
                        formData.append('key['+i+']', data[i])
                    }
                    for(var i = 0; i < data.length;i++){
                        formData.append('key['+i+']', data[i])
                    }
                    formData.append('inc', this.incMesc);

                    Api(context, standardItemName.add_mesc(formData)).onSuccess((response) =>{
                        context.$notify({
                            message: response.data.message,
                            type: 'success',
                            verticalAlign: 'bottom', 
                            horizontalAlign: 'left'
                        });
                        context.handleCurrentChange(context.currentRow);
                    }).onError((error) =>{
                        context.$notify({
                            message: context.tt('error_message'),
                            type: 'danger',
                            verticalAlign: 'bottom', 
                            horizontalAlign: 'left'
                        });
                    }).onFinish(() =>{
                        context.btnAddMesc.onLoading = false;
                        context.mescSub         = '';
                        context.mescMain        = '';
                        context.formMesc.show   = false;
                        context.mescSubMultiple = [];
                        context.handleCurrentChange(context.currentRow);
                    }).call()
                }else{
                    context.btnAddMesc.onLoading = false;
                    if(checkMesc || this.mescMain == ''){
                        this.$notify({
                            message: 'This Main is Already Exits',
                            type: 'danger',
                            verticalAlign: 'bottom', 
                            horizontalAlign: 'left'
                        });
                    }else if(this.incMesc == ''){
                        this.$notify({
                            message: 'Please Select Item Name',
                            type: 'danger',
                            verticalAlign: 'bottom', 
                            horizontalAlign: 'left'
                        });
                    }
                }

            },
            close(show){
                this.mapping.show = show
                this.mapping.keyMesc = ''
            },
            saveMescParent(){
                let data = [];
                let context= this;
                let formData  = new FormData();

                this.btnAddMesc.onLoading = true;
                if(this.incMesc != ''){
                    if(this.mescSubMultiple.length > 0){
                        for(var i=0; i < this.mescSubMultiple.length;i++){
                            data.push(this.mescSubMultiple[i])
                        }
                    }
                    for(var i = 0; i < data.length;i++){
                        formData.append('key['+i+']', data[i])
                    }
                    formData.append('inc', this.incMesc);

                    Api(context, standardItemName.add_mesc(formData)).onSuccess((response) =>{
                        context.$notify({
                            message: response.data.message,
                            type: 'success',
                            verticalAlign: 'bottom', 
                            horizontalAlign: 'left'
                        });
                        context.handleCurrentChange(context.currentRow);
                    }).onError((error) =>{
                        context.$notify({
                            message: context.tt('error_message'),
                            type: 'danger',
                            verticalAlign: 'bottom', 
                            horizontalAlign: 'left'
                        });
                    }).onFinish(() =>{
                        context.btnAddMesc.onLoading = false;
                        context.mescSub         = '';
                        context.mescMain        = '';
                        context.formMescByParent   = false;
                        context.mescSubMultiple = [];
                        context.handleCurrentChange(context.currentRow);
                    }).call()
                }else{
                    context.btnAddMesc.onLoading = false;
                    if(checkMesc || this.mescMain == ''){
                        this.$notify({
                            message: 'This Main is Already Exits',
                            type: 'danger',
                            verticalAlign: 'bottom', 
                            horizontalAlign: 'left'
                        });
                    }else if(this.incMesc == ''){
                        this.$notify({
                            message: 'Please Select Item Name',
                            type: 'danger',
                            verticalAlign: 'bottom', 
                            horizontalAlign: 'left'
                        });
                    }
                }

            },
            saveMescChild(){
                let data = [];
                let context= this;
                let formData  = new FormData();
                let checkMesc = false;

                this.btnAddMesc.onLoading = true;

            
                
                if(this.incMesc != ''){
                    if(this.mescSubSubMultiple.length > 0){
                        for(var i=0; i < this.mescSubSubMultiple.length;i++){
                            data.push(this.mescSubSubMultiple[i])
                        }
                    }
                   data.push(this.mescSub);
                    for(var i = 0; i < data.length;i++){
                        formData.append('key['+i+']', data[i])
                    }
                    formData.append('inc', this.incMesc);

                    Api(context, standardItemName.add_mesc(formData)).onSuccess((response) =>{
                        context.$notify({
                            message: response.data.message,
                            type: 'success',
                            verticalAlign: 'bottom', 
                            horizontalAlign: 'left'
                        });
                        context.handleCurrentChange(context.currentRow);
                    }).onError((error) =>{
                        context.$notify({
                            message: context.tt('error_message'),
                            type: 'danger',
                            verticalAlign: 'bottom', 
                            horizontalAlign: 'left'
                        });
                    }).onFinish(() =>{
                        context.btnAddMesc.onLoading = false;
                        context.mescSub            = '';
                        context.mescMain           = '';
                        context.formMescByParent   = false;
                        context.mescSubMultiple    = [];
                        context.handleCurrentChange(context.currentRow);
                    }).call()
                }else{
                    context.btnAddMesc.onLoading = false;
                    if(checkMesc || this.mescMain == ''){
                        this.$notify({
                            message: 'This Main is Already Exits',
                            type: 'danger',
                            verticalAlign: 'bottom', 
                            horizontalAlign: 'left'
                        });
                    }else if(this.incMesc == ''){
                        this.$notify({
                            message: 'Please Select Item Name',
                            type: 'danger',
                            verticalAlign: 'bottom', 
                            horizontalAlign: 'left'
                        });
                    }
                }

            },
            createMesc(children, key, type){
                this.mescMain = '';
                this.mescSub  = '';
                if(type == 'Main Group'){
                    this.fromMainG = true;
                    this.fromSubG = false;
                    this.mescMain = key;
                    this.mescSubMultiple = [];
                }else{
                    this.fromMainG = false;
                    this.fromSubG = true;
                    this.mescSub  = key;
                    this.mescSubSubMultiple = [];
                }

                this.formMescByParent = true;
            },
            deleteMesc(children,key){
                let context = this;
                if(!Array.isArray(children) && this.incMesc != ''){
                    this.confirmDialog(this.tt('confirm_delete')).then((result) =>{
                        if(result.value){
                            Api(context,standardItemName.delete_mesc(context.incMesc, key)).onSuccess((response) =>{
                                context.$notify({
                                    message: response.data.message,                  
                                    type: 'success',
                                    verticalAlign: 'bottom', 
                                    horizontalAlign: 'left'
                                });
                            }).onError((error) =>{
                                context.$notify({
                                    message: error.response.data.message,                  
                                    type: 'danger',
                                    verticalAlign: 'bottom', 
                                    horizontalAlign: 'left'
                                });
                            }).onFinish(() =>{
                                context.handleCurrentChange(context.currentRow);
                            }).call();
                        }
                    })
                }else{
                    this.$notify({
                        message: 'Sorry, Data have a Children',                  
                        type: 'danger',
                        verticalAlign: "bottom",
                        horizontalAlign: "left"
                    });
                }
            },
            maps(type, key){
                let context = this;
                if(type == 'Main Group'){
                    this.mapping.mainGroup = true;
                    this.mapping.subGroup  = false;
                    this.mapping.subSubGroup = false;
                }else if(type == 'Sub Group'){
                    this.mapping.mainGroup = false;
                    this.mapping.subGroup  = true;
                    this.mapping.subSubGroup = false;
                }else{
                    this.mapping.mainGroup = false;
                    this.mapping.subGroup  = false;
                    this.mapping.subSubGroup = true;
                }

                this.mapping.keyMesc = key;
                setTimeout(() =>{
                    this.mapping.show = true;
                }, 1000)
                
                // Api(context, standardItemName.get_char(this.incMesc, this.mapping.keyMesc)).onSuccess((response) =>{
                //     context.mapping.characteristic = response.data.data.characteristic;
                //     let mescCharacteristic = response.data.data.mescCharacteristic;
                //     context.mapping.mescCharacteristic.cin_code = []
                //     context.mapping.mescCharacteristic.characteristic = []
                //     context.mapping.mescCharacteristic.characteristic_value = []
                //     context.mapping.mescCharacteristic.single_or_multiple = [] 
                //     for(var i = 0; i < mescCharacteristic.length;i++){
                //                 context.mapping.mescCharacteristic.cin_code[i] =mescCharacteristic[i].cin_code;
                //                 context.mapping.mescCharacteristic.characteristic[i] = mescCharacteristic[i].characteristic;
                //                 context.mapping.mescCharacteristic.characteristic_value[i] = mescCharacteristic[i].characteristic_value;
                //                 // // context.mapping.mescCharacteristic[i].view[i] = mescCharacteristic[i].view == 'checked') ? true :  false;
                //                 // // context.mapping.mescCharacteristic[i].mandatory[i] = mescCharacteristic[i].mandatory ? mescCharacteristic[i].mandatory : false;
                //                 context.mapping.mescCharacteristic.single_or_multiple[i] = mescCharacteristic[i].single_or_multiple;
                //                 // // context.mapping.mescCharacteristic[i].code[i] = (mescCharacteristic[i].code == null) ? '' : mescCharacteristic[i].code;
                //     }
                //     context.mapping.show = true;
                //     console.log(context.mapping.mescCharacteristic)
                // }).onError((error) =>{
                //     context.mapping.characteristic = [];
                //     context.mapping.mescCharacteristic = {}
                // }).call();
            },
            saveCharMesc(){
                let context = this;
                let formData = new FormData();

                for(var i = 0; i < this.mapping.charMesc.length; i++){
                    formData.append('cin_code['+i+']', this.mapping.charMesc[i]);
                }
                formData.append('key_mesc', this.mapping.keyMesc)
                Api(context, standardItemName.save_char_mesc(this.incMesc, formData)).onSuccess((response) =>{
                    alert('success');
                }).onError((error) =>{
                    alert('error')
                }).call()
            },
            getMaterialType() {
                let context = this;               
                Api(context, materialType.get({per_page: 'none'})).onSuccess(function(response) {    
                    response.data.data.data.data.forEach(function(item, index) {
                        context.materialType[index] = {
                            'code': item['code'],
                            'value': item['code']
                        };
                    });                    
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.materialType = [];
                    }
                })
                .call()
            },
            addMatType() {
                this.formMatType.show = true;
            },
            saveMatType() {
                let context = this;
                context.btnAddMatType.onLoading = true;
                context.mat_type.inc = context.currentRow.inc;
                context.onLoad.mat_type = true;
                Api(context, standardItemName.add_material_type(this.mat_type)).onSuccess(function(response) {
                    context.$notify({
                        message: response.data.message,
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    context.formMatType.show = false;
                    context.handleCurrentChange(context.currentRow);
                    context.mat_type = {};
                }).onError(function(error) {
                    if (error.response.status == 500) {
                        context.$notify({
                            message: 'Material type isn\'t exist',                  
                            type: 'danger',
                            verticalAlign: 'bottom', 
                            horizontalAlign: 'left'
                        });
                    } else {
                        context.$notify({
                            message: error.response.data.message,                  
                            type: 'danger',
                            verticalAlign: 'bottom', 
                            horizontalAlign: 'left'
                        });
                    }
                }).onFinish(function() {
                    context.btnAddMatType.onLoading = false;
                    context.onLoad.mat_type = false;
                }).call()
            },
            deleteMatType(id) {
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        context.onLoad.mat_type = true;
                        Api(context, standardItemName.delete_material_type(id)).onSuccess(function(response) {
                            if (response.data.data == 1) {
                                context.onLoad.mat_type = false;
                                context.$notify({
                                    message: response.data.message,
                                    type: 'success',
                                    verticalAlign: 'bottom', 
                                    horizontalAlign: 'left'
                                });
                                context.handleCurrentChange(context.currentRow);
                            } else {
                                context.errorMessage = error.response.data;
                            }
                        }).call();
                    }
                })
            },
            getMaterialGroup() {
                let context = this;               
                Api(context, materialGroup.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                    response.data.data.data.data.forEach(function(item, index) {
                        context.materialGroup[index] = {
                            'code': item['code'],
                            'value': item['code']
                        };
                    });                    
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.materialGroup = [];
                    }
                })
                .call()
            },
            addMatGroup() {
                this.formMatGroup.show = true;
            },
            saveMatGroup() {
                let context = this;
                context.btnAddMatGroup.onLoading = true;
                context.mat_group.inc = context.currentRow.inc;
                context.onLoad.mat_group = true;
                Api(context, standardItemName.add_material_group(this.mat_group)).onSuccess(function(response) {
                    context.$notify({
                        message: response.data.message,
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    context.formMatGroup.show = false;
                    context.handleCurrentChange(context.currentRow);
                    context.mat_group = {};
                }).onError(function(error) {
                    if (error.response.status == 500) {
                        context.$notify({
                            message: 'Material group isn\'t exist',                  
                            type: 'danger',
                            verticalAlign: 'bottom', 
                            horizontalAlign: 'left'
                        });
                    } else {
                        context.$notify({
                            message: error.response.data.message,                  
                            type: 'danger',
                            verticalAlign: 'bottom', 
                            horizontalAlign: 'left'
                        });
                    }
                }).onFinish(function() {
                    context.btnAddMatGroup.onLoading = false;
                    context.onLoad.mat_group = false;
                }).call()
            },
            deleteMatGroup(id) {
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        context.onLoad.mat_group = true;
                        Api(context, standardItemName.delete_material_group(id)).onSuccess(function(response) {
                            if (response.data.data == 1) {
                                context.onLoad.mat_group = false;
                                context.$notify({
                                    message: response.data.message,
                                    type: 'success',
                                    verticalAlign: 'bottom', 
                                    horizontalAlign: 'left'
                                });
                                context.handleCurrentChange(context.currentRow);
                            } else {
                                context.errorMessage = error.response.data;
                            }
                        }).call();
                    }
                })
            },
            getServiceCategory() {
                let context = this;               
                Api(context, serviceCategory.get({per_page: 'none'})).onSuccess(function(response) {    
                    response.data.data.data.data.forEach(function(item, index) {
                        context.serviceCategory[index] = {
                            'code': item['code'],
                            'value': item['code']
                        };
                    });                    
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.serviceCategory = [];
                    }
                })
                .call()
            },
            addServiceCategory() {
                this.formServiceCategory.show = true;
            },
            saveServiceCategory() {
                let context = this;
                context.btnAddServiceCategory.onLoading = true;
                context.service_category.inc = context.currentRow.inc;
                context.onLoad.service_category = true;
                Api(context, standardItemName.add_service_category(this.service_category)).onSuccess(function(response) {
                    context.$notify({
                        message: response.data.message,
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    context.formServiceCategory.show = false;
                    context.handleCurrentChange(context.currentRow);
                    context.service_category = {};
                }).onError(function(error) {
                    if (error.response.status == 500) {
                        context.$notify({
                            message: 'Service category isn\'t exist',                  
                            type: 'danger',
                            verticalAlign: 'bottom', 
                            horizontalAlign: 'left'
                        });
                    } else {
                        context.$notify({
                            message: error.response.data.message,                  
                            type: 'danger',
                            verticalAlign: 'bottom', 
                            horizontalAlign: 'left'
                        });
                    }
                }).onFinish(function() {
                    context.btnAddServiceCategory.onLoading = false;
                    context.onLoad.service_category = false;
                }).call()
            },
            deleteServiceCategory(id) {
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        context.onLoad.service_category = true;
                        Api(context, standardItemName.delete_service_category(id)).onSuccess(function(response) {
                            if (response.data.data == 1) {
                                context.onLoad.service_category = false;
                                context.$notify({
                                    message: response.data.message,
                                    type: 'success',
                                    verticalAlign: 'bottom', 
                                    horizontalAlign: 'left'
                                });
                                context.handleCurrentChange(context.currentRow);
                            } else {
                                context.errorMessage = error.response.data;
                            }
                        }).call();
                    }
                })
            },
            getServiceGroup() {
                let context = this;               
                Api(context, serviceGroup.get({per_page: 'none'})).onSuccess(function(response) {    
                    response.data.data.data.data.forEach(function(item, index) {
                        context.serviceGroup[index] = {
                            'code': item['code'],
                            'value': item['code']
                        };
                    });                    
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.serviceGroup = [];
                    }
                })
                .call()
            },
            addServiceGroup() {
                this.formServiceGroup.show = true;
            },
            saveServiceGroup() {
                let context = this;
                context.btnAddServiceGroup.onLoading = true;
                context.service_group.inc = context.currentRow.inc;
                context.onLoad.service_group = true;
                Api(context, standardItemName.add_service_group(this.service_group)).onSuccess(function(response) {
                    context.$notify({
                        message: response.data.message,
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    context.formServiceGroup.show = false;
                    context.handleCurrentChange(context.currentRow);
                    context.service_group = {};
                }).onError(function(error) {
                    if (error.response.status == 500) {
                        context.$notify({
                            message: 'Service group isn\'t exist',                  
                            type: 'danger',
                            verticalAlign: 'bottom', 
                            horizontalAlign: 'left'
                        });
                    } else {
                        context.$notify({
                            message: error.response.data.message,                  
                            type: 'danger',
                            verticalAlign: 'bottom', 
                            horizontalAlign: 'left'
                        });
                    }
                }).onFinish(function() {
                    context.btnAddServiceGroup.onLoading = false;
                    context.onLoad.service_group = false;
                }).call()
            },
            deleteServiceGroup(id) {
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        context.onLoad.service_group = true;
                        Api(context, standardItemName.delete_service_group(id)).onSuccess(function(response) {
                            if (response.data.data == 1) {
                                context.onLoad.service_group = false;
                                context.$notify({
                                    message: response.data.message,
                                    type: 'success',
                                    verticalAlign: 'bottom', 
                                    horizontalAlign: 'left'
                                });
                                context.handleCurrentChange(context.currentRow);
                            } else {
                                context.errorMessage = error.response.data;
                            }
                        }).call();
                    }
                })
            },
            addImageItemName() {
                this.formImageItemName.show = true;
            },
            filesChange(files) {
                this.image_item_name.image = files[0];
            },
            saveImageItemName() {
                let context = this;
                let formData = new FormData();
                context.btnAddImageItemName.onLoading = true;
                context.onLoad.image_item_name = true;
                
                if (context.image_item_name.image.type.includes('image')) {
                    formData.append('inc', context.currentRow.inc);
                    formData.append('image[]', context.image_item_name.image);
                    
                    Api(context, standardItemName.add_image_item_name(formData)).onSuccess(function(response) {
                        context.btnAddImageItemName.onLoading = false;
                        context.formImageItemName.show = false;
                        context.handleCurrentChange(context.currentRow);
                        context.image_item_name = {};
                        context.$notify({
                            message: response.data.message,                  
                            type: 'success',
                            verticalAlign: 'bottom', 
                            horizontalAlign: 'left'
                        });
                    }).onFinish(() => {
                         context.btnAddImageItemName.onLoading = false;
                         context.onLoad.image_item_name = false;
                    }).call()
                } else {
                    context.btnAddImageItemName.onLoading = false;
                    context.onLoad.image_item_name = false;
                    context.$notify({
                        message: 'Format image must jpeg/png.',                  
                        type: 'danger',
                            verticalAlign: 'bottom', 
                            horizontalAlign: 'left'
                    }); 
                    context.errorMessage = {
                        status: "Fail",
                        message: "Standard Item Name Validation Error",
                        data: {
                            abbreviation: [
                                "Format image must jpeg/png."
                            ]
                        }
                    };
                }

            },
            deleteImageItemName(id) {
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        context.onLoad.image_item_name = true;
                        Api(context, standardItemName.delete_image_item_name(id)).onSuccess(function(response) {
                            if (response.data.data == 1) {
                                context.onLoad.image_item_name = false;
                                context.$notify({
                                    message: response.data.message,
                                    type: 'success',
                                    verticalAlign: 'bottom', 
                                    horizontalAlign: 'left'
                                });
                                context.handleCurrentChange(context.currentRow);
                            } else {
                                context.errorMessage = error.response.data;
                            }
                        }).onFinish(function() {
                            context.onLoad.image_item_name = false;
                        }).call();
                    }
                })


                // this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                //     let context = this;
                //     if (result.value) {
                //         context.onLoad.service_group = true;
                //         Api(context, standardItemName.delete_service_group(id)).onSuccess(function(response) {
                //             if (response.data.data == 1) {
                //                 context.onLoad.service_group = false;
                //                 context.$notify({
                //                     message: response.data.message,
                //                     type: 'success',
                //                     verticalAlign: 'bottom', 
                //                     horizontalAlign: 'left'
                //                 });
                //                 context.handleCurrentChange(context.currentRow);
                //             } else {
                //                 context.errorMessage = error.response.data;
                //             }
                //         }).call();
                //     }
                // })
            },
            generateInc() {
                let context = this;
                context.btnGenerate.onLoading = true;
                Api(context, standardItemName.generate_inc()).onSuccess(function(response) {                                       
                    context.standard_item_name.inc = response.data.data;
                }).onError(function(error) {                    
                    context.errorMessage = error.response.data;
                }).onFinish(function() {
                    context.btnGenerate.onLoading = false;
                }).call();
            },
            tab(type) {
                if (type == 'definition') {
                    this.tabs.definition = true;
                    this.tabs.colloquial = false;
                    this.tabs.ngc = false;
                    this.tabs.mat_type = false;
                    this.tabs.mat_group = false;
                    this.tabs.service_category = false;
                    this.tabs.service_group = false;
                    this.tabs.image_item_name = false;
                    this.tabs.mesc = false;
                    this.tabs.unspsc = false;
                } else if (type == 'colloquial') {
                    this.tabs.definition = false;
                    this.tabs.colloquial = true;
                    this.tabs.ngc = false;
                    this.tabs.mat_type = false;
                    this.tabs.mat_group = false;
                    this.tabs.service_category = false;
                    this.tabs.service_group = false;
                    this.tabs.image_item_name = false;
                    this.tabs.mesc = false;
                    this.tabs.unspsc = false;
                } else if (type == 'ngc') {
                    this.tabs.definition = false;
                    this.tabs.colloquial = false;
                    this.tabs.ngc = true;
                    this.tabs.mat_type = false;
                    this.tabs.mat_group = false;
                    this.tabs.service_category = false;
                    this.tabs.service_group = false;
                    this.tabs.image_item_name = false;
                    this.tabs.mesc = false;
                    this.tabs.unspsc = false;
                } else if (type == 'mat_type') {
                    this.tabs.definition = false;
                    this.tabs.colloquial = false;
                    this.tabs.ngc = false;
                    this.tabs.mat_type = true;
                    this.tabs.mat_group = false;
                    this.tabs.service_category = false;
                    this.tabs.service_group = false;
                    this.tabs.image_item_name = false;
                    this.tabs.mesc = false;
                    this.tabs.unspsc = false;
                } else if (type == 'mat_group') {
                    this.tabs.definition = false;
                    this.tabs.colloquial = false;
                    this.tabs.ngc = false;
                    this.tabs.mat_type = false;
                    this.tabs.mat_group = true;
                    this.tabs.service_category = false;
                    this.tabs.service_group = false;
                    this.tabs.image_item_name = false;
                    this.tabs.mesc = false;
                    this.tabs.unspsc = false;
                } else if (type == 'service_category') {
                    this.tabs.definition = false;
                    this.tabs.colloquial = false;
                    this.tabs.ngc = false;
                    this.tabs.mat_type = false;
                    this.tabs.mat_group = false;
                    this.tabs.service_category = true;
                    this.tabs.service_group = false;
                    this.tabs.image_item_name = false;
                    this.tabs.mesc = false;
                    this.tabs.unspsc = false;
                } else if (type == 'service_group') {
                    this.tabs.definition = false;
                    this.tabs.colloquial = false;
                    this.tabs.ngc = false;
                    this.tabs.mat_type = false;
                    this.tabs.mat_group = false;
                    this.tabs.service_category = false;
                    this.tabs.service_group = true;
                    this.tabs.image_item_name = false;
                    this.tabs.mesc = false;
                    this.tabs.unspsc = false;
                } else if (type == 'image_item_name') {
                    this.tabs.definition = false;
                    this.tabs.colloquial = false;
                    this.tabs.ngc = false;
                    this.tabs.mat_type = false;
                    this.tabs.mat_group = false;
                    this.tabs.service_category = false;
                    this.tabs.service_group = false;
                    this.tabs.image_item_name = true;
                    this.tabs.mesc = false;
                    this.tabs.unspsc = false;
                } else if (type == 'mesc'){
                    this.tabs.definition = false;
                    this.tabs.colloquial = false;
                    this.tabs.ngc = false;
                    this.tabs.mat_type = false;
                    this.tabs.mat_group = false;
                    this.tabs.service_category = false;
                    this.tabs.service_group = false;
                    this.tabs.image_item_name = false;
                    this.tabs.mesc = true;
                    this.tabs.unspsc = false;
                } else if (type == 'unspsc'){
                    this.tabs.definition = false;
                    this.tabs.colloquial = false;
                    this.tabs.ngc = false;
                    this.tabs.mat_type = false;
                    this.tabs.mat_group = false;
                    this.tabs.service_category = false;
                    this.tabs.service_group = false;
                    this.tabs.image_item_name = false;
                    this.tabs.mesc = false;
                    this.tabs.unspsc = true;
                }
            },
            setCurrent(row) {
                this.$refs.singleTable.setCurrentRow(row);
            },
            handleCurrentChange(val) {
                let context = this;
                context.currentRow = val;
                this.incMesc = val.inc;

                if (context.tabs.definition) {
                    context.onLoad.definition = true;
                } else if (context.tabs.colloquial) {
                    context.onLoad.colloquial = true;
                } else if (context.tabs.ngc) {
                    context.onLoad.ngc = true;
                } else if (context.tabs.mat_type) {
                    context.onLoad.mat_type = true;
                } else if (context.tabs.mat_group) {
                    context.onLoad.mat_group = true;
                } else if (context.tabs.service_category) {
                    context.onLoad.service_category = true;
                } else if (context.tabs.service_group) {
                    context.onLoad.service_group = true;
                } else if (context.tabs.image_item_name) {
                    context.onLoad.image_item_name = true;
                } else if (context.tabs.mesc){
                    context.onLoad.mesc = true
                }else if(context.tabs.unspsc){
                    context.onLoad.unspsc = true
                }

                Api(context, standardItemName.data(val.inc)).onSuccess(function(response) {
                    context.selectedData.definition = response.data.data.data_definition[0];
                    context.selectedData.colloquial = response.data.data.data_colloquial;
                    context.selectedData.ngc = response.data.data.data_group_class;
                    context.selectedData.mesc = response.data.data.data_mesc;
                    context.selectedData.mat_type = response.data.data.data_material_type;
                    context.selectedData.mat_group = response.data.data.data_material_group;
                    context.selectedData.service_category = response.data.data.data_service_category;
                    context.selectedData.service_group = response.data.data.data_service_group;
                    context.selectedData.image_item_name = {};
                    response.data.data.data_image.forEach(function(item, index){
                        context.selectedData.image_item_name[index] = {
                            'id': item['id'],
                            'image': config.storageUrl+'images/image_item_name/'+item['image']
                        };
                    })
                    context.selectedData.all_image_item_name = [];
                    response.data.data.data_image.forEach(function(item){
                        context.selectedData.all_image_item_name.push(config.storageUrl+'images/image_item_name/'+item['image'])
                    })
                    context.selectedData.unspsc = response.data.data.data_unspsc
                    
                })
                .onError(function (error) {
      
                })
                .onFinish(function() {
                    if (context.onLoad.definition) {
                        context.onLoad.definition = false;
                    } else if (context.onLoad.colloquial) {
                        context.onLoad.colloquial = false;
                    } else if (context.onLoad.ngc) {
                        context.onLoad.ngc = false;
                    } else if (context.onLoad.mat_type) {
                        context.onLoad.mat_type = false;
                    } else if (context.onLoad.mat_group) {
                        context.onLoad.mat_group = false;
                    } else if (context.onLoad.service_category) {
                        context.onLoad.service_category = false;
                    } else if (context.onLoad.service_group) {
                        context.onLoad.service_group = false;
                    } else if (context.onLoad.image_item_name) {
                        context.onLoad.image_item_name = false;
                    } else if (context.onLoad.mesc) {
                        context.onLoad.mesc = false;
                    }else if(context.onLoad.unspsc){
                        context.onLoad.unspsc = false
                    }
                }).call()
            },
            handleCurrentChangeNgc(val) {
                let context = this;
                context.onLoad.group_definition = true;
                Api(context, standardItemName.group_class_def(val.nsc)).onSuccess(function(response) {
                    if (context.tabs.ngc) {
                        context.selectedData.group_definition = response.data.data[0].definition_eng;
                    } else {
                        context.selectedData.group_definition = '-';
                    }
                }).onFinish(function() {
                    context.onLoad.group_definition = false;
                }).call()
            },
            get() {
                let context = this;

                Api(context, standardItemName.get(this.table.page.current_page, this.$route.query)).onSuccess(function(response) {
                    context.table.total = response.data.data.data.total;
                    context.table.data = response.data.data.data.data;
                    context.table.page  = response.data.data.data;
                    context.onLoad.standard_item_name = false;
                    context.onLoad.definition = false;
                }).onError(function(error) {
                    if (error.response.status == 404) {
                        context.table.data = [];
                        context.table.total = 0;
                        context.onLoad.standard_item_name = false;
                        context.onLoad.definition = false;
                    }
                }).call()
            },
            filter() {
                this.$router.push({ path: 'standard-item-name', query: this.searchStandardItemName });

                this.btnSearch.onLoading = true;
                this.onLoad.standard_item_name = true;
                this.table.page.current_page = 1;
                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get();
                    this.searchStandardItemName = {
                        'item_name': '',
                        'colloquial': '',
                        'definition': '',
                        'group_class': '',
                    }  
                    this.btnSearch.onLoading = false;
                    this.formSearch.show = false;
                    this.onLoad.standard_item_name = false;
                }, 100);
            },
            handleTableAction(command) {
                switch (command.action) {
                    case 'edit':
                            this.edit(command.data);
                        break;
                    case 'remove':
                            this.remove(command.data);
                        break;
                    default:
                        break;
                }
            },
            create() {
                this.errorMessage = null;
                this.form.add = true;
                this.form.title = this.tt('add_item_name');
                this.form.show = true;
                this.standard_item_name = {};
                this.standardItemName = {
                    'definition_eng': '',
                    'definition_ind': '',
                };
                this.$refs.form.reset();
            },            
            edit(id) {
                let context = this;      
                context.errorMessage = null;

                Api(context, standardItemName.show(id)).onSuccess(function(response) {
                    context.standard_item_name = response.data.data;
                    context.form.add = false;
                    context.form.title = context.tt('update_item_name');
                    context.form.show = true;                          
                })
                .call()        
            },
            save() {                      
                let context = this;
                let api = null;
                context.btnSave.onLoading = true;

                if (context.form.add) {
                    api = Api(context, standardItemName.create(context.standard_item_name));
                } else {{
                    api = Api(context, standardItemName.update(context.standard_item_name.id, context.standard_item_name));
                }}

                api.onSuccess(function(response) {
                    context.get();
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });                    
                    context.form.show = false;
                }).onError(function(error) {                    
                    context.errorMessage = error.response.data;
                }).onFinish(function() {
                    context.btnSave.onLoading = false;   
                })
                .call();
            },
            remove(id) {
                let context = this;
                context.confirmDialog(context.tt('confirm_delete')).then((result) => {
                    if (result.value) {
                        context.onLoad.standard_item_name = true;
                        Api(context, standardItemName.delete(id)).onSuccess(function(response) {
                            context.get();
                            context.$notify({
                                message: response.data.message,
                                type: 'success',
                                verticalAlign: 'bottom', 
                                horizontalAlign: 'left'
                            });
                        }).onFinish(function() {
                            context.onLoad.standard_item_name = false;
                        }).call();
                    }
                })
            },
            search() {
                this.formSearch.show = true
            },
            image() {
                this.formImage.show = true
            },
            closeImage() {
                this.formImage.show = false
            },
            send(id) {
                this.confirmDialog(this.tt('confirm_send')).then((result) => {
                    if (result.value) {
                        this.alertDialog('success', this.tt('success_send'));
                    } else {
                        this.alertDialog('error', this.tt('error_send'));
                    }
                })
            },

            querySearchNgc(queryString, cb) {
                var links = this.links_ngc;
                var results = queryString ? links.filter(this.createFilter(queryString)) : links;
                cb(results);
            },
            querySearchMatType(queryString, cb) {
                var links = this.links_mat_type;
                var results = queryString ? links.filter(this.createFilter(queryString)) : links;
                cb(results);
            },
            querySearchMatGroup(queryString, cb) {
                var links = this.links_mat_group;
                var results = queryString ? links.filter(this.createFilter(queryString)) : links;
                cb(results);
            },
            querySearchServiceCategory(queryString, cb) {
                var links = this.links_service_category;
                var results = queryString ? links.filter(this.createFilter(queryString)) : links;
                cb(results);
            },
            querySearchServiceGroup(queryString, cb) {
                var links = this.links_service_group;
                var results = queryString ? links.filter(this.createFilter(queryString)) : links;
                cb(results);
            },
            querySearchMescMainG(query) {
                if (query.length > 0) {
                    clearTimeout(this.selectSearch.loadTimeout);             
                    let context = this;   
                    let formData = new FormData();
                    formData.append('mainG',true);
                    formData.append('search', query)
                    this.selectSearch.mainG = true;
                    
                    this.selectSearch.loadTimeout = setTimeout(() => {
                        Api(context, Mesc.search(formData)).onSuccess(function(response) {
                            context.mescMainGroup = response.data.data;    
                            context.selectSearch.mainG = false;                                           
                        }).onError(function(error) {
                            context.mescMainGroup = [];
                            context.selectSearch.mainG = false;
                        }).call();             
                    }, 200)
                }
            },
            getSubGroup(){
                let context = this;
                let formData = new FormData();
                formData.append('parent_key', this.mescMain)
                Api(context, Mesc.search(formData)).onSuccess((response) => {
                    context.mescSubGroup = response.data.data;
                }).onError((error) =>{
                    context.mescSubGroup = []
                }).call()
            },
            getSubSubGroup(){
                let context = this;
                let formData = new FormData();
                formData.append('parent_key', this.mescSub)
                Api(context, Mesc.search(formData)).onSuccess((response) => {
                    context.mescSubSubGroup = response.data.data;
                }).onError((error) =>{
                    context.mescSubSubGroup = []
                }).call()
            },
            createFilter(queryString) {
                return (link) => {
                  return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
                };
            },
            loadAll(materialType) {
                return materialType;
            },
            handleSelect() {
                
            },
            changePage(page) {
                let context = this;
                context.onLoad.standard_item_name = true;

                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()
                }, 100);
            },
            formUnspsc(data = null){
                let level = data ? data.level : 0
                this.dataUnspsc.show = true;
                this.dataUnspsc.btnSave = false;
                this.dataUnspsc.parent_code = data.identity_relation_code ? data.identity_relation_code : "null"

                if(level == 1){
                    this.dataUnspsc.activeForm = 'family'
                }else if(level == 2){
                    this.dataUnspsc.activeForm = 'classification'
                }else if(level == 3){
                    this.dataUnspsc.activeForm = 'commodity'
                }else{
                    this.dataUnspsc.activeForm = 'segment'
                }

                if(level != 0){
                    this.searchUnspsc(level + 1)
                }
                this.dataUnspsc.sg_code = []
                this.dataUnspsc.fm_code = []
                this.dataUnspsc.cl_code = []
                this.dataUnspsc.cm_code = []
            }, 
            searchSegmentCode(query){
                this.searchUnspsc(1, query)
            },
            searchUnspsc(level, query = null){
                let variabel = "";
                if(level == 1){
                    variabel = "segmentCodes"
                }else if(level == 2){
                    variabel = "familyCodes"
                }else if(level == 3){
                    variabel = "classificationCodes"
                }else if(level  == 4){
                    variabel = "commodityCodes"
                }

                let dataQuery = {}
                if(level != 1){
                    dataQuery.level = level;
                    dataQuery.parent_code = this.dataUnspsc.parent_code
                }else{
                    dataQuery.level = level;
                    dataQuery.search = query
                }

                let context = this;
                    Api(context, unspsc.get(dataQuery)).onSuccess((response) =>{
                    context[variabel]= response.data.data.data;
                }).onError((error) =>{
                    context[variabel] = [];
                }).call()
            },
            saveUnspsc(){
                let parameter = ""
                if(this.dataUnspsc.activeForm == 'segment'){
                    parameter = "sg_code"
                }else if(this.dataUnspsc.activeForm == 'family'){
                    parameter = "fm_code"
                }else if(this.dataUnspsc.activeForm == 'classification'){
                    parameter = "cl_code"
                }else if(this.dataUnspsc.activeForm == 'commodity'){
                    parameter = "cm_code"
                }
                let context = this;
                let formData = new FormData();
                for(var i = 0; i < this.dataUnspsc[parameter].length; i++){
                    formData.append("identity_relation_code["+i+"]", this.dataUnspsc[parameter][i])
                }
                this.dataUnspsc.btnSave = true
                Api(context, standardItemName.save_unspsc(context.currentRow.inc,formData)).onSuccess((response) =>{
                    context.dataUnspsc.btnSave = false
                    context.dataUnspsc.show = false;
                     context.$notify({
                        message: response.data.message,
                        type: "success",
                        verticalAlign: "bottom",
                        horizontalAlign: "left"
                    });
                    context.handleCurrentChange(context.currentRow);
                }).onError((error)=>{
                     context.$notify({
                        message: error.response.data.message,
                        type: "danger",
                        verticalAlign: "bottom",
                        horizontalAlign: "left"
                    });
                } ).call()
            }
        }   
    };
</script>
<style>
     .el-tooltip__popper,
        .el-tooltip__popper {
      padding: 8px 10px;
      width:400px;
    }
</style>
