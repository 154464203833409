import Service from "@/helpers/service";

export default {
  get(params) {
    return Service().get("unspsc", {params});
  },
  dataChildren(params) {
    return Service().get("unspsc/children", {params});
  },

  byItemName(inc){
    return Service().get("unspsc/item-name/" + inc)
  }
};